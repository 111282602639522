html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  /* font-family: 'Arimo', sans-serif; */
  font-family: 'Raleway', sans-serif;
  color: #464646;
  line-height: 1.42;
  width: 100%;
  overflow-x: hidden;
}

*, ::after, ::before {
  box-sizing: inherit;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#site-loader {
  width: 100vw;
  height: 100vh;
  background: #eee;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.7s;
}

.site-loader-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.site-loader-content img {
  background: #eee;
  padding: 0 20px;
}

.sl-lines {
  display: block;
  width: 50%;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #cda9af;
  z-index: -1;
}

.sl-lines-l {
  left: 0;
  text-align: right;
}

.sl-lines-r {
  right: 0;
}

.sl-lines span {
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #d00929;
  z-index: -1;
  animation: runn 2.5s infinite ease;
  margin-top: -2px;
}

.sl-lines-l span {
  right: 0;
  transform-origin: right center;
}

.sl-lines-r span {
  left: 0;
  text-align: right;
  transform-origin: left center;
}

@keyframes runn {
  0% {
      transform: scaleX(0)
  }

  50% {
      transform: scaleX(1)
  }

  to {
      transform: scaleX(0)
  }
}

p {
  margin: 0 0 10px 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Roboto Condensed', sans-serif;
  color: #130202;
  margin: 0 0 20px 0;
}

.Nvl-section {
  padding: 80px 0;
}

.Nvl-container {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

@media screen and (min-width: 1200px) {
  .Nvl-container {
    max-width: 1200px;
    padding: 0;
  }
}

.Nvl-row {
  display: flex;
}

@media screen and (max-width: 967px) {
  .Nvl-row {
    flex-direction: column;
  }
}

.Nvl-row.Nvl-row-wrap {
  flex-wrap: wrap;
}

.Nvl-row.Nvl-row-aic {
  align-items: center;
}

.Nvl-row.Nvl-row-ait {
  align-items: top;
}

.Nvl-row.Nvl-row-aib {
  align-items: bottom;
}

.Nvl-row.Nvl-row-jcsb {
  justify-content: space-between;
}

.Nvl-row.Nvl-row-jcsa {
  justify-content: space-around;
}

.Nvl-row.Nvl-row-aicjcsb {
  align-items: center;
  justify-content: space-between;
}

.Nvl-row .Nvl-col {
  flex: 1;
}

.Nvl-row .Nvl-col-33 {
  width: 33%;
  flex-basis: 33%;
}

.Nvl-img-flex {
  width: 100%;
}

.Nvl-section-title {
  display: inline-block;
  font-size: 32px;
  position: relative;
  margin-bottom: 44px;
}

.Nvl-section-title > span {
  color: #d00929;
}

.Nvl-section-title:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  background: #d00929;
}

/* .Nvl-section-title::after {
  content: "";
  display: block;
  width: 70%;
  height: 3px;
  background: #d00929;
} */

.Nvl-txt-center {
  text-align: center!important;
}

.Nvl-block {
  display: block!important;
}

.Nvl-btn {
  background: #222;
  border: 2px solid #222;
  color: #eee;
  transition: all 0.3s;
  line-height: 1;
  padding: 12px 28px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.Nvl-btn.Nvl-btn-link {
  background: none;
  color: #222;
  border: none;
  text-decoration: none;
  padding: 2px 5px;
}

.Nvl-btn.Nvl-btn-link:hover {
  text-decoration: underline;
}

.Nvl-btn.Nvl-btn-red {
  background: #d00929;
  color: #eee;
  border-color:#d00929;
}

.Nvl-btn.Nvl-btn-white {
  background: #fff;
  color: #222;
  border-color:#fff;
}

.Nvl-btn:not(.Nvl-btn-outline):not(.Nvl-btn-link):after {
  content: "";
  display: block;
  width: 0;
  height: 4px;
  position: absolute;
  top: calc(100% - 2px);
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s;
  background: #d00929;
}

.Nvl-btn:not(.Nvl-btn-outline):not(.Nvl-btn-link):hover:after {
  width: calc(100% + 4px);
}

.Nvl-btn.Nvl-btn-red:not(.Nvl-btn-outline):not(.Nvl-btn-link):after {
  background: #222;
}

.Nvl-btn.Nvl-btn-outline {
  background: transparent;
  color: #222;
}

.Nvl-btn.Nvl-btn-outline:hover {
  background: #222;
  color: #fff;
}

.Nvl-btn.Nvl-btn-outline.Nvl-btn-red {
  color: #d00929;
}

.Nvl-btn.Nvl-btn-outline.Nvl-btn-red:hover {
  background: #d00929;
  color: #fff;
}

.Nvl-btn.Nvl-btn-outline.Nvl-btn-white {
  color: #fff;
}

.Nvl-btn.Nvl-btn-outline.Nvl-btn-white:hover {
  background: #fff;
  color: #d00929;
}

.Nvl-btn > i {
  margin-right: 6px;
}

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}

.z-depth-3 {
  box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}

.z-depth-4 {
  box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}

.Nvl-before-bg:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .4;
  background-size: cover;
}

@media screen and (max-width: 1024px) {
  .sm-txt-c {
    text-align: center;
  }
}

/*
=======================================
  Masthead
=======================================
*/
.nvl-layout-masthead-wrapper {
  width: 100%;
  position: fixed;
  background: #d00929;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0,0,0,.4);
  padding: 0 20px;
}

.Nvl-masthead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.masthead-logo {
  width: 180px;
  display: block;
}

.Nvl-main-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .Nvl-main-nav {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .masthead-logo {
    width: 120px;
  }
}

.Nvl-nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Nvl-nav-list-link {
  display: block;
  padding: 4px;
  margin: 8px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
}

.Nvl-nav-list-link:not(.Nvl-order-online) {
  transform: translateY(4px);
}

.Nvl-nav-list-link:not(.Nvl-order-online):after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #222;
  margin-top: 4px;
  transition: all 0.4s;
}

.Nvl-nav-list-link:hover:after {
  width: 100%;
}

.Nvl-order-online {
  color: #fff;
  background: #222;
  padding: 8px 18px;
  transition: all 0.4s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Nvl-order-online > span {
  transform: translateY(2px);
  margin-left: 6px;
  font-weight: bold;
}

.Nvl-order-online:hover {
  background: #222;
  color: #fff;
}

@media screen and (max-width: 1310px) {
  .Nvl-order-online {
    margin-right: 50px;
  }
}

.menu-handle {
  position: fixed;
  cursor: pointer;
  right: 20px;
  top: 24px;
  z-index: 1001;
}

@media screen and (min-width: 1024px) {
  .menu-handle {
    display: none;
  }
}

.menu-handle.closed .menu-handle-icon {
  overflow: inherit
}

.menu-handle.closed .menu-handle-icon .menu-handle-line {
  -webkit-transition: all .8s cubic-bezier(.985,.005,0,1.25);
  transition: all .8s cubic-bezier(.985,.005,0,1.25)
}

.menu-handle.closed .menu-handle-icon .menu-handle-line--top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 30px
}

.menu-handle.closed .menu-handle-icon .menu-handle-line--top:after {
  display: none
}

.menu-handle.closed .menu-handle-icon .menu-handle-line--middle {
  width: 0
}

.menu-handle.closed .menu-handle-icon .menu-handle-line--middle:after {
  display: none
}

.menu-handle.closed .menu-handle-icon .menu-handle-line--bottom {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  width: 30px
}

.menu-handle.closed .menu-handle-icon .menu-handle-line--bottom:after {
  display: none
}

.menu-handle-icon {
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 22px;
  display: inline-block;
  vertical-align: middle
}

.menu-handle-line {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease
}

.menu-handle.closed .menu-handle-line {
  background-color: #222;
}

.menu-handle-line--top {
  top: 0;
  width: 5px
}

.menu-handle-line--top:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  margin-left: 5px;
  left: 100%;
  display: block;
  background-color: #fff;
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease
}

.menu-handle-line--middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.menu-handle-line--bottom {
  bottom: 0;
  width: 20px
}

.menu-handle-line--bottom:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  margin-left: 5px;
  left: 100%;
  display: block;
  background-color: #fff;
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease
}

.menu-handle:not(.closed):hover .menu-handle-line {
  -webkit-transition: all .5s cubic-bezier(.985,.005,0,1.65);
  transition: all .5s cubic-bezier(.985,.005,0,1.65)
}

.menu-handle:not(.closed):hover .menu-handle-line--top {
  width: 20px
}

.menu-handle:not(.closed):hover .menu-handle-line--bottom {
  width: 5px
}

/*
=================================================
  Hero
=================================================
*/

.Nvl-hero-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
}

.Nvl-video-fsbg {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
}

.Nvl-video-fsbg > iframe,
.Nvl-video-fsbg > video {
  position: absolute;
  top: -90px;
  left: 50%;
  right: 0px;
  bottom: 0px;
  transform: translateX(-50%);
}

.Nvl-hero-content {
  position: absolute;
  width: 52%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 20px;
  text-align: center;
}

@media (max-width: 660px) {
  .Nvl-hero-content {
    width: 92%;
  }
}

/* .Nvl-hero-subtitle {
  display: inline-block;
  position: relative;
  color: #fff;
  line-height: 1.2;
  font-size: 26px;
  opacity: 0;
  transform: translateY(-60px);
  transition: all 1s;
}

.Nvl-hero-subtitle:after,
.Nvl-hero-subtitle:before {
  content: "";
  display: block;
  width: 32px;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
}

.Nvl-hero-subtitle:after {
  right: 104%;
}

.Nvl-hero-subtitle:before {
  left: 104%;
} */

.Nvl-hero-title {
  text-align: center;
}

.Nvl-hero-title h1 {
  color: #fff;
  text-shadow: 1px 1px 2px black;
  transition: all 1s;
}

.Nvl-hero-title h1 span {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 5.2rem;
}

/* .Nvl-hero-title h1 span:nth-child(1) {
  font-size: 1.8rem;
}

.Nvl-hero-title h1 span:nth-child(2) {
  font-size: 3.2rem;
} */

.Nvl-hero-title h1 span:nth-child(3) {
  font-size: 1.4rem;
  font-weight: normal;
  font-style: italic;
  transform: translateY(-10px);
}

.hero-cta {
  display: flex;
  padding: 1rem 2rem;
  background: #d00929;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: all 1s;
  align-items: center;
  justify-content: center;
}

.hero-cta:hover {
  transform: translateY(-3px);
}

.hero-cta img {
  width: 44px;
  margin-right: 10px;
}

/* .Nvl-hero-subtitle.el-in-view,
.Nvl-hero-title.el-in-view {
  opacity: 1;
  transform: translateY(0);
} */

/*
=================================================
  Welcome
=================================================
*/
.Nvl-section-welcome {
  background: #e9e9e9;
  border-top: 8px solid #d00929;
}

.Nvl-welcome-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Nvl-section-welcome .Nvl-img-flex {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.8s;
}

.Nvl-section-welcome .Nvl-img-flex.el-in-view {
  opacity: 1;
  transform: translateY(0);
}

/*
=================================================
  Locations
=================================================
*/
.Nvl-section#Locations {
  position: relative;
}

.Nvl-section#Locations:before {
  background: url(/assets/images/qbkls.png);
}

.locations-list {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  grid-gap: 40px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .locations-list {
    grid-template-columns: 1fr;
  }
}

#Locations .locations-list:nth-of-type(2) {
  transform: translateX(100%);
}

#Locations .locations-list.el-in-view {
  opacity: 1;
  transform: translatex(0);
}

.locations-list .location-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  transition: all 0.4s;
  border: 1px solid #eeefea;
  background: #fff;
}

.location-item-order-btn {
  margin: 15px auto;
}

.location-item.hovered {
  background: #eeefea;
}

.location-item figure {
  position: relative;
  padding: 0;
  margin: 10px;
}

.location-item .Nvl-img-flex {
  position: relative;
  transition: all 0.8s;
}

.location-item .Nvl-img-flex:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.location-item.hovered figure img:nth-child(2) {
  opacity: 0;
}

.location-item-info {
  width: 90%;
}

/*

*/
#map-canvas {
  height: 600px;
}

.map-location-modal {
  padding: 20px;
  border: 3px solid #d00929;
  width: 100%;
  height: 100%;
}

.Nvl-x {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Nvl-section#Menus {
  background: #eaeaea url(/assets/images/sushibg.png) center left no-repeat;
}

.akk-menus-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.akk-menus-list a {
  display: block;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: #000;
  margin: 14px 0;
  border-bottom: 1px solid #aeaeae;
  padding: 12px;
  transition: all 0.4s;
}

.akk-menus-list a:hover {
  transform: translateX(3px);
}

/*
=================================================
  Gallery
=================================================
*/
#Gallery {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}

.pswp-thumbnails {
  display: none;
}

#Gallery:after {
  left: auto;
  right: -24%;
}

.fj-gallery-item {
  overflow: hidden;
}

.fj-gallery-item .fj-gallery-item-x {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.fj-gallery-item  .Nvl-img-flex {
  position: relative;
  top: 0;
  left:0;
}

.fj-gallery-item .Nvl-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: all 0.4s;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  padding: 8px 14px;
  font-size: 13px;
}

.fj-gallery-item:hover .Nvl-btn {
  opacity: 1;
}

/*
=================================================
  CTA Banner
=================================================
*/
#cta-banner {
  padding: 6rem 0;
  background: #111;
  position: relative;
}

#cta-banner:before {
  background: url(/assets/images/qbkls.png);
  opacity: .22;
}

.cta-order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  border: 2px solid transparent;
  transition: all .4s;
  cursor: pointer;
  padding: 10px 24px;
  line-height: 1;
  margin: 0 auto;
  position: relative;
  background: none;
}

#cta-banner:hover .cta-order-btn,
.cta-order-btn:hover {
  border-color: #fff;
}

.cta-order-btn:before,
.cta-order-btn:after {
  content: "";
  display: block;
  width: 200px;
  height: 2px;
  position: absolute;
  background: #fff;
  top: 50%;
  left: -100%;
  transition: all 0.4s;
}

.cta-order-btn:after {
  left: auto;
  right: -100%;
}

.cta-order-btn:hover:after {
  right: -94%;
}

.cta-order-btn:hover:before {
  left: -94%;
}

/*
====================================
  Footer
====================================
*/
#Nvl-footer {
  padding: 40px 0 0 0;
  background: #d00929;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
  grid-column-gap: 30px;
  margin: 0 auto;
  padding: 0 20px;
  align-items: center;
}

@media screen and (min-width: 1199px) {
  .footer-grid {
    max-width: 1140px;
  }
}

.footer-contact-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  grid-column-gap: 30px;
}

@media screen and (max-width: 1024px) {
  .footer-grid {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .footer-grid > div:nth-child(2) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.footer-contact-links li {
  margin-bottom: 15px;
}

.footer-contact-links a {
  display: inline-block;
}

.footer-contact-links i {
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #333;
  color: #fff;
  text-align: center;
  line-height: 42px;
  font-size: 21px;
  transition: all 0.3s;
}

.footer-contact-links i:hover {
  box-shadow: 0 16px 20px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}

.sponsor-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-column-gap: 30px;
}

.footer-contact-links i:hover {
  box-shadow: 0 16px 20px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}

.Nvl-copyright {
  background: #ad001c;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  padding: 5px 0;
  font-size: 12px;
  color: #ddd;
  grid-column-gap: 10px;
}

.gdpr-links {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  padding: 5px 0;
  font-size: 12px;
  color: #ddd;
  grid-column-gap: 10px;
}

.gdpr-links a {
  color: #eee;
  text-decoration: none;
}


/*
====================================
  Order Modal
====================================
*/
.order-modal-content {
  padding: 20px;
  border: 8px solid #d00929;
}

.order-modal-item {
  padding: 12px 5px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.3s;
}

.order-modal-item:hover {
  background: #d00929;
  color: #fff;
}

/*
====================================
  Side menu
====================================
*/
#side-menu {
  position: fixed;
  top: 0;
  right: -102%;
  width: 300px;
  height: 100vh;
  background: #e9e9e9;
  z-index: 1000;
  box-shadow: -10px 0px 10px 1px rgba(0,0,0,0.2);
  transition: all .4s;
}

.side-menu-inner:before {
  background: url(/assets/images/qbkls.png);
  opacity: 0.2;
  z-index: -1;
}

.side-menu-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.Nvl-side-nav-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 20px 0;
  padding: 0;
}

.Nvl-side-nav-list-link {
  display: block;
  font-weight: 800;
  text-decoration: none;
  font-size: 24px;
  color: #d00929;
  padding: 1px 6px;
  margin: 4px 0 4px 0;
}

.Nvl-side-nav-list-link:hover {
  background: #d00929;
  color: #fff;
}

.sidemenu-contacts {
  width: 340px;
  margin: 20px 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: flex-start;
  grid-column-gap: 20px;
}

.sidemenu-contacts li {
  margin-bottom: 15px;
}

.sidemenu-contacts a {
  display: inline-block;
}

.sidemenu-contacts i {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #333;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  transition: all 0.3s;
}
